
/* eslint-disable */
import Vue from "vue"
import moment from 'moment'


export default Vue.component("BalanceList", {

    props: [
        "item",
        "claseGeneral",
        "originBot",
    ],
    data() {
        return {
            PagarAntesDel: "Pagar antes del",
            dialogBalanceList: false,
            dialog: false,
            SlideType: {
                BodySlideBalance: 0,
                BodySlideBalanceSuspended: 1,
                BodySlideCirculoAzul: 2,
                BodySlideForPeriod: 3,
                BodySlideForTime: 4,
                BodySlideSocialNetworks: 5,
                BodySlideTable: 6,
                BodySlideWithGraph2Columns: 7,
                BodySlideSocialNetworksCluster: 8,
            },
            options: {
                plotOptions: {
                    radialBar: {
                        startAngle: -90,
                        endAngle: 90,
                        track: { background: '#e0e0e0', startAngle: -90, endAngle: 90, },
                        dataLabels: {
                            show: true,
                            name: {
                                show: false,
                            },
                            value: {
                                show: true,
                                fontSize: '18px',
                                fontFamily: undefined,
                                fontWeight: 400,
                                color: undefined,
                                offsetY: 0,
                                formatter: function (val: any) {
                                    return val.toFixed(0) + '%'
                                }
                            }
                        }
                    }
                }
            },
            facebookIcon: require('@/assets/Img/Claro/CL/facebook.png'),
            twitterIcon: require('@/assets/Img/Claro/CL/twitter.png'),
            whatsappIcon: require('@/assets/Img/Claro/CL/whatsapp.png'),
            instagramIcon: require('@/assets/Img/Claro/CL/instagram.png'),
            claromusicaIcon: require('@/assets/Img/Claro/CL/claromusica.png'),
            snapchatIcon: require('@/assets/Img/Claro/CL/snapchat.png'),
            wazeIcon: require('@/assets/Img/Claro/CL/waze.png'),
            messengerIcon: require('@/assets/Img/Claro/CL/messenger.png'),
            youtubeIcon: require('@/assets/Img/Claro/CL/youtube.svg'),
            colaboraIcon: require('@/assets/Img/Claro/CL/colaboracloud.png'),
            gmailIcon: require('@/assets/Img/Claro/CL/gmail.png'),
            hotmailIcon: require('@/assets/Img/Claro/CL/hotmail.png'),
            yahooIcon: require('@/assets/Img/Claro/CL/yahoo.png'),
            tntSportshdIcon: require('@/assets/Img/Claro/CL/tntSportsHD.png'),
            logos_hbomIcon: require('@/assets/Img/Claro/CL/logos_hboM.png'),
            disableButtonDetails: false
        }
    },
    mounted() {
        if (this.originBot == "TelcelBot") {
            this.options.plotOptions.radialBar.track.background = "#e0e0e0";
        } else {
            this.options.plotOptions.radialBar.track.background = "#DA291C";
        }
    },
    methods: {
        obtenFechaActual(fechaActual: Date) {
            return fechaActual ? moment(fechaActual).lang("es-mx").format("DD/MM/YYYY") : "Error";
        },
        obtenerPorcentajeBarraGb(total: string) {
            return total + "%";
        },
        obtenerPorcentajeBarraMin(total: string, remaining: string) {
            let res = ((parseInt(remaining) * 100) / parseInt(total)).toString() + "%";
            console.log("Res: " + res);
            return res;
        },
        obtenerImagen(idImage: string) {
            if (idImage.includes("messenger")) return this.messengerIcon;
            if (idImage.includes("facebook")) return this.facebookIcon;
            if (idImage.includes("twitter")) return this.twitterIcon;
            if (idImage.includes("whatsapp")) return this.whatsappIcon;
            if (idImage.includes("instagram")) return this.instagramIcon;
            if (idImage.includes("claromusica")) return this.claromusicaIcon;
            if (idImage.includes("snapchat")) return this.snapchatIcon;
            if (idImage.includes("waze")) return this.wazeIcon;
            if (idImage.includes("colaboracloud")) return this.colaboraIcon;
            if (idImage.includes("gmail")) return this.gmailIcon;
            if (idImage.includes("hotmail")) return this.hotmailIcon;
            if (idImage.includes("yahoo")) return this.yahooIcon;
            if (idImage.includes("tntSportsHD")) return this.tntSportshdIcon;
            if (idImage.includes("logos_hboM")) return this.logos_hbomIcon;
            if (idImage.includes("17")) return "youtube.png";
            if (idImage.includes("18")) return "facebook.png";
            if (idImage.includes("19")) return "youtube.png";
            if (idImage.includes("20")) return "instagram.png";
        },
        mostrarDetalle(type: any, title: string){
            if((type === null || type == undefined) && title != undefined && title != null){
                this.disableButtonDetails = false;
                return true;
            }else{
                this.disableButtonDetails = true;
                return false;
            }
        }

    }

})
