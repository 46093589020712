
import Vue from "vue";
export default Vue.component("FaqList", {
    props: ["item"],
    data() {
        return{
           dialogCarrousel: false,
            carousel: 0
        };
    },
    mounted(){
       window.addEventListener('keydown', (event) => {
            const keyName = event.key;
            if(keyName == 'ArrowRight'){
                this.carousel ++ ;
            }
            else if(keyName == 'ArrowLeft'){
                this.carousel --;
            }
        })
    },
    methods: {
        
    },
});
