
/* eslint-disable */
import Vue from "vue";
import AzureService from "@/services/azure.service";
import { AudioConfig, ResultReason, SpeechConfig, SpeechRecognizer, } from 'microsoft-cognitiveservices-speech-sdk';

import jQuery from "jquery";
let $ = jQuery;

var ejecutaCronometro:any;
var stopAudio:any;
var recordAudioStart:any;
var recordAudioStop:any;


let speechConfig:any;
let audioConfig:any;
export default Vue.component("Record",{
  props: [
    "claroOscuro",
    "pais", 
    "originBot", 
    "footerDisabled",
    "TimeMicrophoneVoice",
    "disabledRecord"
  ],
  
  data() {
      return {
          showDialogVoice:String(localStorage.getItem('showDialogVoice'))=="true",
          classRecord:'recording',
          recognizer: Object(SpeechRecognizer) ,
          recognizing: false ,
          text:'',
          textComplete: "",
          state :{ displayText: 'INITIALIZED: ready to test speech...' },
          token:"",
          regionCognitive:"",
          regionlocale:"",
          showTooltipTimeRecord:false,
          timeHablado:"00:00",
          segundos:0,
          minutos:0
      }
  },
  computed:{
    disabledBtn():Boolean { 
        return this.disabledRecord;
    }
  },
  methods: {
    getToken(){
      AzureService.getToken().then((resp)=>{
        this.token = resp.data.token;
        this.regionCognitive = resp.data.regionCognitive;
        this.regionlocale = resp.data.regionlocale;

      speechConfig = SpeechConfig.fromAuthorizationToken(this.token, this.regionCognitive);
      speechConfig.speechRecognitionLanguage = this.regionlocale;            
      audioConfig = AudioConfig.fromDefaultMicrophoneInput();

      }).catch(error =>{
        console.error("Ocurrio un error al invocar el servicio de record " + error);
      });
    },

    /**Inicializa el record mouse*/
    mousedownRecordSpeech: function (event:any){
      if(!this.recognizing && event.button == 0){
        this.touchstartRecordSpeech();
      }
    },
    /**Inicializa el record touch*/
    touchstartRecordSpeech: function () {
      if (!this.recognizing) {
        clearTimeout(recordAudioStop);
        let segundos = this.TimeMicrophoneVoice + 1;
        let milisegundos = segundos * 1000;
        stopAudio = setTimeout(() => {  if (this.recognizing) {this.RecordSpeechStop();} }, milisegundos); 
        this.sttFromMicStart();
      }
    },

    async sttFromMicStart() {
      this.textComplete ='';
      this.text = '';
      this.recognizing = true;

      this.recognizer = new SpeechRecognizer(speechConfig, audioConfig);
      this.recognizer.recognizing = this.recognizer.recognized = this.recognizerCallback.bind(this)
      this.recognizer.startContinuousRecognitionAsync();
      recordAudioStart = setTimeout(() => {
        this.$emit("footerDisabledRec", true);
        this.$emit("labelTextArea", "Hablar ahora...");
        this.timerHablado();
        this.classRecord = 'stop';
      },500);
    },
    recognizerCallback(s:any, e:any) {
      let scroll: any = document.querySelector(".scroll");
      setTimeout(() => { let containerChat: any = document.querySelector("#containerChat"); scroll.scrollTop = containerChat.scrollHeight; }, 50);
        const reason = ResultReason[e.result.reason];
        if (reason == "RecognizingSpeech") {
            var result = e.result.text;
            var mytext = this.textComplete + result;
            mytext = mytext.charAt(0).toUpperCase() + mytext.slice(1);
            this.text = mytext.replace(/(.*)(^|[\r\n]+).*\[\.\.\.\][\r\n]+/, '$1$2');
            
            this.$emit("text",this.text);
        }
        if (reason == "RecognizedSpeech") {
            var result = e.result;
            this.onRecognizedResult(result);
        }
    },
    onRecognizedResult(result:any){
        if(result.reason == 3){
            this.textComplete = this.text + ' ';
            this.$emit("text",this.textComplete);
        }  
    },

    /**Finaliza el record mouse*/
    RecordSpeechStop: function () {
      if (this.recognizing) {
        this.touchendRecordSpeechStop();
      }
    },
    touchendRecordSpeechStop: function () {
      clearInterval(ejecutaCronometro);
      clearTimeout(recordAudioStart);
      clearTimeout(stopAudio);
      this.sttFromMicStop();
    },
    async sttFromMicStop() {
      this.showTooltipTimeRecord = false;
      this.$emit("disabledRecord", true);
      this.recognizing = false;
      recordAudioStop = setTimeout(() => {
        this.stop();
        this.$emit("footerDisabledRec", false);
        this.$emit("labelTextArea", "Escribe tu consulta");
        this.classRecord = 'recording';
        
      },1000);
      
    },
    stop() {
        this.recognizer.stopContinuousRecognitionAsync(
        this.stopRecognizer.bind(this),
        this.stopErrorRecognizer.bind(this)
        )
    },
    stopRecognizer() {
      this.$emit("sendText");
      this.recognizer.close();
      this.recognizer = Object(SpeechRecognizer);
    },
    stopErrorRecognizer() {
      this.$emit("sendText");
      this.recognizer.close();
      this.recognizer = Object(SpeechRecognizer);
    },


    timerHablado() {
      this.showTooltipTimeRecord = true;
      this.segundos = 0;
      this.minutos = 0;
      this.timeHablado = "00:00";
      clearInterval(ejecutaCronometro);
      ejecutaCronometro = setInterval(()=>{
        this.segundos = this.segundos + 1;
        if(this.segundos == 60){
          this.minutos = this.minutos + 1
          this.segundos = 0;
        }
        this.timeHablado = this.formato(this.minutos)+":"+this.formato(this.segundos)
      },1000)
    },
    formato(aux:any){
      if(aux < 10){
        return "0"+aux;
      }else{
        return aux;
      }
    },
    soloTexto(){
      this.$emit('showVoice',false); 
      $('.mytext-default').css('width',  'calc(100% - 40px)'); 
      this.showDialogVoice = false
    }
  },
});
