
/* eslint-disable */
import Vue from "vue";
import "@/assets/CSS/EstiloGeneral.css";
import "@/assets/CSS/EstiloGeneralVue.css";
import "@/assets/CSS/EstiloColorClaro.css";
import "@/assets/CSS/EstiloColorOscuro.css";
import { DATOS } from "@/assets/json/datos";

import ChatService from "@/services/chat.service";

import Header from "@/components/Header.vue";
import DirectLine from "@/components/DirectLine.vue";
import ContenidoChatBot from "@/components/ContenidoChatBot.vue";
import ContenidoChatAgent from "@/components/ContenidoChatAgent.vue"
import Record from "@/components/Record.vue";
import SpellCheck from "@/components/SpellCheck.vue";
import Emoji from "@/components/Emoji.vue";
import Typing from "@/components/Typing.vue";
import SignalR from "@/components/SignalR.vue";

import CardActions from '@/models/CardActions'
import VueSlider from 'vue-slider-component';
import 'vue-slider-component/theme/default.css';

import Chat from "@/models/chat";
import chatService from "@/services/chat.service";


import jQuery from "jquery";
import { Console } from "console";
let $ = jQuery;
let counterInactivity: any;


const DEFAULT_INACTIVITY_TIME = 300;
const MIN_INACTIVITY_TIME = 60;
const whileClose = 100000000;

export default Vue.extend({
    name: "Chat",

    components: {
        'Header': Header,
        'DirectLine': DirectLine,
        'ContenidoChatBot': ContenidoChatBot,
        'ContenidoChatAgent': ContenidoChatAgent,
        'Record': Record,
        'Emoji': Emoji,
        'Typing': Typing,
        'SignalR': SignalR,
        'SpellCheck': SpellCheck,
        'VueSlider': VueSlider,
    },

    data() {
        return {
            disabledBtnFinalizaEncuesta: false,
            messageEndChatAgent: "El asesor cerró la conversación",
            timeoutAfterInactivityFinishChat: 0,
            entryType: '',
            respQuestionnaire: {},
            questionnaire: [],
            icons: [
                'mdi-snowflake',
                'mdi-leaf',
                'mdi-fire',
                'mdi-water',
            ],
            claroOscuro: String(localStorage.getItem('claroOscuro')),
            originBot: String(localStorage.getItem('originBot')),
            pais: String(localStorage.getItem('pais')),
            urlRedirigir: String(localStorage.getItem('urlRedirigir')),
            showVoice: String(localStorage.getItem('showVoice')) == "true",
            isEnableSpellCheckBot: false,
            isEnableSpellCheckAgent: false,
            IsEnableSentReceivedViewed: false,
            ShowSentReceivedViewedAcesor: false,
            padless: false,
            showPassword: false,
            isPassword: false,
            showTypingBot: true,
            footerDisabledRec: false,
            footerDisabled: true,
            centerHeight: document.documentElement.clientHeight,
            heightFooter: 0,
            heightTxtArea: 0,
            heightContainEmoji: 0,

            isBot: true,
            thereAreMessagesClient: false,

            typeUser: {
                Client: 'Client',
                Agent: 'Agent',
                Server: 'Server',
                Bot: 'Bot',
                Agentclose: 'Agentclose'
            },
            AppType: {
                AppIOS: 'AppIos',
                AppAndroid: 'AppAndroid'
            },
            ChatFinishBy: {
                Agent: '7798A28F-CD1C-49E9-BAD4-1DD845610EDB',
                Client: 'B069DBA1-96EB-4D20-A52E-D4EF18427469',
                Inactivity: 'B7767FE9-3A14-4DC9-8817-A9041E8C9DE1',
                BOT: '83D9E245-E125-439B-8845-BA3D76FAA559'
            },
            emisorMessage: {
                Me: 'me',
                You: 'you',
                Server: 'server'
            },
            keyChatUrl: "",
            chatEnc: "",
            ChatComplete: "",
            dataSaveComplementChat: "",
            inactivityMessage: "",
            userName: "",
            conversationToken: "",
            guidSystemCallCenter: "",
            timeActivity: new Date(),
            timeInactivityMessage: 0,
            timeInactivityEndAgent: 0,
            timeInactivityEndBot: 0,
            showInactivityMessage: 0,
            TimeMicrophoneVoice: 0,
            SignalR_startRetries: 0,
            SignalR_serverTimeout: 0,
            SignalR_keepAliveInterval: 0,
            GuidChatState: "",
            appType: "",
            isWriteMessageInactivity: true,
            isflagExternalFrame: false,
            isReconnect: false,
            isFirstClosechat: true,
            hideNextMessage: false,
            keyChat: "",
            Region: "",
            listConversation: new Array<Chat>(),
            text: "",
            conversationID: "",
            token: "",
            iconoAvatar: "",
            iconAvatarAgente: "",
            closingAction: "",
            redirectUrl: "",
            channelRedirect: "",
            sChannel: "",
            RedirectUrl: "",
            waClientNumber: "",
            messageTextSpellCheck: "",
            urlApiSpellCheck: "",
            pathSpellCheck: "",
            paramsSpellCheck: "",
            KeySpellCheck: "",
            iconAvatar: "",
            imgTypingGif: "",
            iconAgent: "",

            validacionTxtArea: [(v: any) => (v || '').length <= 150 || 'El número máximo de caracteres aceptado son 150',],
            iconSent: require('@/assets/Img/sent.png'),
            iconReceived: require('@/assets/Img/received.png'),
            iconViewed: require('@/assets/Img/viewed.png'),
            cerroVentana: false,
            questionnaireStatus: {
                sinContestar: "Sin contestar",
                vencido: "Vencido",
                contestado: "Contestado"
            },
            questionnaireStatusResponse: '',
            typesView: {
                chat: "Chat",
                questionnaire: "Cuestionario",
            },
            typeView: "Chat",
            urlExternalFrames: "",
            urlGenericExternalFrame: "",
            quizOnOff: 0,
            numberInteractions: 0,
            fechaInicioQualtrix: "",
            openModalQualtrix: false,
            containQuiz: false,
            closeQualtrixInClose: false,
            disabledRecord: false,
            surveyLink: String(localStorage.getItem('surveyLink')),
            setClick: true,
            isOpenModalQualtrix: false,
            labelTextArea: "Escribe tu consulta",
            hValidateStatus: false,
            auxContadorReset: 0,
            banderaStatus: <any>setInterval(() => { }),
            isIgnoreSpellCheck: false

        };
    },
    created() {
        this.autoResize();
    },

    mounted() {
        console.log("Pagina chat");
        this.keyChatUrl = this.$route.params.id;
        window.history.replaceState({}, '', this.keyChatUrl + "/" + this.originBot);
        var cuestionarioService = localStorage.getItem("cuestionarioService-" + this.keyChatUrl);
        if (this.originBot == "TelcelBot") {
            this.iconoAvatar = require('@/assets/Img/Telcel/icon-avatar.png');
            this.iconAvatarAgente = require('@/assets/Img/Telcel/icn_agente.png');
        } else {
            this.iconoAvatar = require('@/assets/Img/Claro/icon-avatar.png');
            this.iconAvatarAgente = require('@/assets/Img/Claro/icn_agente.png');
        }
        window.onresize = () => {
            this.centerHeight = document.documentElement.clientHeight;
            this.autoResize();
        }
        if (cuestionarioService != null) {
            var responseQuestions = JSON.parse(cuestionarioService);
            this.typeView = this.typesView.questionnaire;
            this.questionnaireStatusResponse = responseQuestions.QuestionnaireStatus;
            if (this.questionnaireStatusResponse == this.questionnaireStatus.sinContestar) {
                this.keyChat = this.keyChatUrl;
                this.isBot = responseQuestions.isBot;
                this.appType = responseQuestions.appType;
                this.sChannel = responseQuestions.channel;
                this.llenarValoresCuestionario(responseQuestions);
            } else {
                localStorage.removeItem("cuestionarioService-" + this.keyChatUrl);
            }
        } else {
            window.addEventListener('unload', (e: any) => {
                console.log("unload", e);
                alert("aduiso")
                if (window.confirm("se cerro la ventana")) {
                    console.log("adios")
                }
                if (!this.cerroVentana) {
                    this.endChatClient(this.ChatFinishBy.Client)
                    let tmp = 0;
                    while (tmp < whileClose) {
                        tmp++;
                    }
                    this.limpiarStorage();
                }
            })
            window.addEventListener('beforeunload', (e: any) => {
                console.log("beforeunload", e);
                alert("aduiso")
                if (window.confirm("se cerro la ventana")) {
                    console.log("adios")
                }
                this.endChatClient(this.ChatFinishBy.Client);
                this.limpiarStorage();
                this.cerroVentana = true;
                let tmp = 0;
                while (tmp < whileClose) {
                    tmp++;
                }
            })
            window.addEventListener('message', (e: any) => {
                this.metodoIframe(e);
            });
            this.cargaInicial();
            this.typingIcon();
        }

    },
    methods: {
        cargaInicial() {
            let clientData: any = localStorage.getItem("clientData-" + this.keyChatUrl);
            localStorage.removeItem("clientData-" + this.keyChatUrl);
            if (this.showVoice) {
                $(".mytext-default").css("width", "calc(100% - 80px)");
                let record: any = this.$refs.record;
                record.getToken();
            } else {
                $(".mytext-default").css("width", "calc(100% - 40px)");
            }

            let DirectLine: any = this.$refs.DirectLine;
            let emoji: any = this.$refs.emoji;
            ChatService.cargaInicial(clientData).then((resp) => {
                let data: any = resp.data;
                if (resp.data.Code == 300 || resp.data.Code == 500) {
                    this.$router.push('/Error');
                } else {
                    localStorage.setItem('UrlBaseChat', resp.data.UrlBaseChat);
                    localStorage.setItem('UrlGeneralApi', resp.data.UrlGeneralApi);
                    localStorage.setItem('SocketServer', resp.data.SocketServer);
                    this.conversationToken = data.conversationToken;
                    this.quizOnOff = resp.data.ActiveQuizQualtrix;
                    this.keyChat = data.keyChat;
                    this.Region = data.Region;
                    console.log("keyChat: " + this.keyChat);
                    this.token = data.Token;
                    this.chatEnc = data.Chat;

                    this.timeInactivityMessage = data.TimeInactivityClient;
                    this.timeInactivityEndAgent = data.TimeInactivity
                    if (this.timeInactivityEndAgent < MIN_INACTIVITY_TIME) {
                        this.timeInactivityEndAgent = DEFAULT_INACTIVITY_TIME;
                    }
                    this.timeInactivityEndBot = data.TimeInactivityBot;
                    this.showInactivityMessage = data.InactivityChat;
                    this.TimeMicrophoneVoice = data.TimeMicrophoneVoice;
                    this.inactivityMessage = data.inactivityMessage;

                    this.SignalR_startRetries = data.SignalR_startRetries;
                    this.SignalR_serverTimeout = data.SignalR_serverTimeout;
                    this.SignalR_keepAliveInterval = data.SignalR_keepAliveInterval;
                    this.GuidChatState = data.GuidChatState;
                    this.appType = data.appType;
                    this.closingAction = data.ClosingActionModel;
                    this.redirectUrl = data.RedirectUrl;
                    this.channelRedirect = data.ChannelRedirect;
                    this.sChannel = data.Channel;
                    this.timeoutAfterInactivityFinishChat = data.TimeoutAfterInactivityFinishChat != undefined ? data.TimeoutAfterInactivityFinishChat : 2;
                    this.ChatComplete = data.ChatComplete;
                    let clientDataObj = JSON.parse(clientData)
                    this.dataSaveComplementChat = clientDataObj.dataSaveComplementChat;
                    // this.writeMessage("Tu plan actual es: Max XL Libre#Ver detalle#Detalle mucho detalle",this.typeUser.Bot);
                    ChatService.SaveChat({ chat: this.ChatComplete }).then(resp => {
                        if (this.dataSaveComplementChat != undefined && this.dataSaveComplementChat != null && this.dataSaveComplementChat != "" && this.dataSaveComplementChat != "|||") {
                            ChatService.SaveChatComplement(this.ChatComplete, String(this.dataSaveComplementChat));
                        }
                        DirectLine.cargaInicial(this.conversationToken, this.keyChat, data.InitialTransaction);
                    }).catch(error => {
                        console.log("ocurrio un error al guardar la conversación");
                        DirectLine.cargaInicial(this.conversationToken, this.keyChat, data.InitialTransaction);
                    });

                    this.isEnableSpellCheckBot = data.IsEnableSpellCheckBot == 'true';
                    this.isEnableSpellCheckAgent = data.IsEnableSpellCheckAgent == 'true';

                    this.urlApiSpellCheck = data.UrlApiSpellCheck;
                    this.pathSpellCheck = data.PathSpellCheck;
                    this.paramsSpellCheck = data.ParamsSpellCheck;
                    this.KeySpellCheck = data.KeySpellCheck;

                    this.initInfoForQualtrix();
                    emoji.getEmojis(this.keyChat);
                    this.footerDisabled = false;
                    this.startCountInactivity();
                    this.typingIcon();
                }
            })
                .catch((err) => {
                    console.error("Ocurrio un error ", err);
                });
        },
        sendGroup(text: any) {
            let SignalR: any = this.$refs.SignalR;
            SignalR.sendGroup(this.userName, text, this.typeUser, this.guidSystemCallCenter, this.hideNextMessage, this.messageTextSpellCheck, this.entryType);
        },
        sendTextSpellCheck(isRecord: Boolean) {
            this.messageTextSpellCheck = '';
            let reg = /<(.|\n)*?>/g;
            if (!this.footerDisabledRec && reg.test(this.text) === false && this.text.trim() !== "") {
                if (isRecord) {
                    this.entryType = "V";
                } else {
                    this.entryType = "T"
                }
                let SpellCheck: any = this.$refs.SpellCheck;
                if (this.isBot) {
                    if (this.isEnableSpellCheckBot == true
                        && !this.text.startsWith('#ExternalFrameResult')
                        && !this.text.startsWith('#ExternalFrameResultGeneric')
                        && this.text != '#Inactivity#' && !this.isPassword
                        && !this.isIgnoreSpellCheck) {
                        SpellCheck.getCorrectText(this.text);
                    } else {
                        this.isIgnoreSpellCheck = false;
                        this.sendText();
                    }
                } else {
                    if (this.isEnableSpellCheckAgent == true && !this.text.startsWith('#ExternalFrameResult') &&
                        !this.text.startsWith('#ExternalFrameResultGeneric') && this.text != '#Inactivity#' && this.hideNextMessage != true && !this.isIgnoreSpellCheck) {
                        SpellCheck.getCorrectText(this.text);
                    } else {
                        this.isIgnoreSpellCheck = false;
                        this.sendText();
                    }
                }
            } else {
                this.disabledRecord = false;
            }
        },
        sendText() {
            if (!this.footerDisabledRec) {
                let reg = /<(.|\n)*?>/g;
                if (reg.test(this.text) === false && this.text.trim() !== "") {
                    this.thereAreMessagesClient = true;
                    if (this.isBot) {
                        this.listConversation.forEach((item: Chat) => {
                            if (item.cardActions != undefined && item.cardActions != null && item.cardActions.length > 0) {
                                item.cardActions.forEach((cardAction: any) => {
                                    cardAction.disable = true;
                                });
                            }
                        });
                        let DirectLine: any = this.$refs.DirectLine;
                        this.showTypingBot = true;
                        let uuidv4 = this.uuidv4();
                        this.SaveMesssage(this.keyChat, this.userName, this.text, this.typeUser.Client, uuidv4);
                        DirectLine.sendActivityToBotHijo(this.messageTextSpellCheck != '' ? this.messageTextSpellCheck : this.text, uuidv4, "write");
                        this.writeMessage(this.text, this.userName);
                        this.numberInteractions += 1;
                    } else {
                        this.sendGroup(this.text);
                    }
                }
                this.text = '';
                this.messageTextSpellCheck = '';
            }
        },
        clickLinkChat(input: any) {
            this.entryType = "B";
            this.thereAreMessagesClient = true;
            let DirectLine: any = this.$refs.DirectLine;
            this.text = input.value.trim();
            this.listConversation.forEach((item: Chat) => {
                if (item.cardActions != undefined && item.cardActions != null && item.cardActions.length > 0) {
                    item.cardActions.forEach((cardAction: any) => {
                        cardAction.disable = true;
                        if (input.id == cardAction.id) {
                            cardAction.active = true;
                        }
                    });
                }
            });
            let messageId = this.uuidv4();
            this.SaveMesssage(this.keyChat, this.userName, this.text, this.typeUser.Client, messageId);
            this.writeMessage(input.title.trim(), this.typeUser.Client);
            DirectLine.sendActivityToBot(this.text, messageId, "button");
            this.showTypingBot = true;
            this.text = '';

            this.numberInteractions += 1;
        },
        selectAccount(account: any) {
            this.entryType = "T";
            let DirectLine: any = this.$refs.DirectLine;
            this.text = account.accountNumber;
            let messageId = this.uuidv4();
            this.SaveMesssage(this.keyChat, this.userName, this.text, this.typeUser.Client, messageId);
            this.writeMessage(account.accountNumber.trim(), this.typeUser.Client);
            DirectLine.sendActivityToBot(this.text, messageId, "write");
            this.showTypingBot = true;
            this.text = '';
            localStorage.setItem('accountSelect', JSON.stringify(account))
            this.numberInteractions += 1;
        },
        getLocation(location: string)
        {
            let DirectLine: any = this.$refs.DirectLine;
            this.text = location;
            let messageId = this.uuidv4();
            this.SaveMesssage(this.keyChat, this.userName, this.text, this.typeUser.Client, messageId);
            DirectLine.sendActivityToBot(this.text, messageId, "write");
            this.showTypingBot = true;
            this.text = '';
            this.numberInteractions += 1;
            this.listConversation.forEach((item: Chat) => {
                if (item.cardActions != undefined && item.cardActions != null && item.cardActions.length > 0) {
                    item.cardActions.forEach((cardAction: any) => {
                        cardAction.disable = true;
                    });
                }
            });
        },
        /**PINTAR MENSAJE  */
        writeMessage(ContentChatBot: any, typeUser: string) {
            let conversation = new Chat();
            if (typeUser == this.typeUser.Bot) {
                this.disabledRecord = false;
                conversation = ContentChatBot;
                conversation.typeUser = this.typeUser.Bot;
                conversation.typeMessage = ContentChatBot.typeMessage
                conversation.cardActions = ContentChatBot.cardActions;
                conversation.time = this.formatAMPM(new Date());
                conversation.message = ContentChatBot.message;
                conversation.timestamp = ContentChatBot.timestamp;
                if (conversation.message.includes("#HideNextMessage#")) {
                    this.isPassword = true;
                    return;
                }
                else if (conversation.message.includes("#hvalidatestatus#")) {
                    this.footerDisabled = true;
                    let DirectLine: any = this.$refs.DirectLine;
                    this.hValidateStatus = true;
                    this.auxContadorReset = 20;

                    this.banderaStatus = setInterval(() => {
                        console.log("Ejecución cada 20 seg.", new Date())
                        DirectLine.sendActivityToBot(this.auxContadorReset, this.uuidv4(), "");
                        this.auxContadorReset += 20;
                    }, 20000)
                    return;
                }
                else if (conversation.message.includes("#hvalidatestatusend#")) {
                    this.footerDisabled = false;
                    this.hValidateStatus = false;
                    clearInterval(this.banderaStatus);
                    this.auxContadorReset = 0;
                    return;
                }
                else if(conversation.message.includes("#IgnoreSpellCheck#")) {
                    this.isIgnoreSpellCheck = true;
                    return;
                }
            } else if (typeUser == this.typeUser.Agent) {
                conversation = ContentChatBot.message;
                conversation.typeUser = this.typeUser.Agent;
                conversation.timestamp = new Date().getTime();
                conversation.nameAgent = ContentChatBot.nameAgent;
                conversation.time = this.formatAMPM(new Date());
                let SignalR: any = this.$refs.SignalR;
                SignalR.isSentReceivedViewedCallback("Received");
                this.ShowSentReceivedViewedAcesor = true;
            } else if (typeUser == this.typeUser.Agentclose) {
                conversation.message = ContentChatBot.message;
                conversation.typeUser = this.typeUser.Agentclose;
                conversation.timestamp = new Date().getTime();
            } else if (typeUser == this.typeUser.Server) {
                if (ContentChatBot.message != undefined) {
                    conversation = ContentChatBot.message;
                } else {
                    conversation.message = ContentChatBot;
                }
                conversation.typeUser = this.typeUser.Server;
                conversation.timestamp = new Date().getTime();
            } else {
                this.footerDisabled = false;
                if (ContentChatBot.message != undefined) {
                    conversation = ContentChatBot.message
                } else {
                    conversation.message = ContentChatBot;
                }
                if (conversation.message.includes('hideMessageForClient::documentUrl:')) {
                    let url = conversation.message.replace('hideMessageForClient::documentUrl:', '');
                    conversation.message = url;
                    if (conversation.message.includes('.jpg')
                        || conversation.message.includes('.png')
                        || conversation.message.includes('.jpeg')) {

                        conversation.topicName = "img";
                    } else {
                        conversation.topicName = "document";
                    }
                }
                conversation.typeUser = this.typeUser.Client;
                conversation.timestamp = new Date().getTime();
                conversation.time = this.formatAMPM(new Date());
                if (this.isPassword) {
                    if (this.entryType == "T") {
                        conversation.message = conversation.message.replace(/./g, '*');
                    }
                    this.isPassword = false;
                }
                if (!this.isBot) {
                    conversation.sentReceivedViewed = 'Sent';
                    this.ShowSentReceivedViewedAcesor = false;
                }
                this.restartCounter();
                this.inactivaAccountList();
            }
            conversation.message = conversation.message.replace(/\n/g, "<br />");
            conversation.message = this.ValidateQualtrixQuizInAnswer(conversation.message);
            if(this.containQuiz){
                console.log("Conversation: " + conversation.message);
                this.validateQualtrixQuiz(conversation.cardActions, conversation.message);
            }
            this.listConversation.push(conversation);
            this.listConversation.sort(((a, b) => a.timestamp - b.timestamp));
            if (this.thereAreMessagesClient) {
                const scroll: any = document.querySelector(".scroll");
                setTimeout(() => { let containerChat: any = document.querySelector("#containerChat"); scroll.scrollTop = containerChat.scrollHeight; }, 50);
            }
            this.containQuiz = false;
        },
        inactivaAccountList() {
            if (this.isWriteMessageInactivity) {
                this.listConversation.forEach((item: Chat) => {
                    if (item.topicName != undefined && item.topicName != null && item.topicName == 'AccountList') {
                        item.disabled = true
                    }

                });

            }
        },
        /** Guarda mensaje en el storage */
        SaveMesssage(keyChat: any, name: any, message: any, typeUser: any, messageId: any) {
            let data = {
                keyChat, conversationID: this.conversationID, name, message, typeUser, messageId, messageTextSpellCheck: this.messageTextSpellCheck, entryType: this.entryType
            };
            ChatService.SaveMesssage(data);
        },
        /**Inicia inactividad */
        startCountInactivity() {
            this.clearInactivity();
            console.log("Inicio Interval Counter Inactivity");
            counterInactivity = setInterval(() => { this.inactividadCliente() }, 1000);
        },
        clearInactivity() {
            console.log("Limpio Interval Counter Inactivity");
            clearInterval(counterInactivity);
            this.isWriteMessageInactivity = true;
        },
        inactividadCliente() {
            if (this.isBot) {
                this.inactividadBot();
            } else {
                this.inactividadAgente();
            }
        },

        inactividadBot() {
            let diffMs = (new Date().getTime() - this.timeActivity.getTime());
            let diffSeg = Math.floor(diffMs / 1000);
            if (this.showInactivityMessage == 1 && diffSeg >= this.timeInactivityMessage && diffSeg < this.timeInactivityEndBot && this.isWriteMessageInactivity && !this.isflagExternalFrame) {
                this.isWriteMessageInactivity = false;
                ChatService.Inactividad().then(data => {
                    console.log(data);
                    let DirectLine: any = this.$refs.DirectLine;
                    data.data.timestamp = new Date();
                    DirectLine.TranslatMesssage(data.data);
                }).catch(error => {
                    this.isWriteMessageInactivity = true;
                    console.error(error)
                });
            }
            if (diffSeg >= this.timeInactivityEndBot) {
                $('.options-register-user').remove();
                this.clearInactivity()
                this.endChatClient(this.ChatFinishBy.Inactivity);
            }
        },
        inactividadAgente() {
            let diffMs = (new Date().getTime() - this.timeActivity.getTime());
            let diffSeg = Math.floor(diffMs / 1000);
            if (diffSeg > this.timeInactivityEndAgent) {
                this.clearInactivity()
                this.endChatClient(this.ChatFinishBy.Inactivity);
                console.log("Finaliza por tiempo de inactividad con el Agente");
            }
        },
        restartCounter() {
            this.timeActivity = new Date();
            if (this.isBot) {
                this.isWriteMessageInactivity = true;
            }
        },
        /**Finaliza inactividad */
        /** Inicializa cerrar conversacion */
        CloseConversation() {
            if (false) {
                //if(this.ShowQualtrixQuiz() && !this.closeQualtrixInClose){
                this.validateQualtrixQuiz(null, "");
                this.autoClick();
                this.observer();
            }
            else if (this.isBot) {
                let storage = window.localStorage;
                storage.clear();
                this.endChatBot(this.ChatFinishBy.Client);
                if (this.closingAction.toLowerCase() === 'close' || this.appType.toLowerCase() === 'web') {
                    setTimeout(() => {
                        if (this.channelRedirect !== this.sChannel) {
                            self.close();
                            setTimeout(() => { this.$router.push("/Chat/FinalChat"); }, 1000)

                        } else {
                            setTimeout(function () {
                                window.location.href = '/Chat/FinalChat';
                            }, 500);
                        }
                    }, 500);
                } else {
                    window.location.href = this.redirectUrl;
                }
            } else {
                if (this.appType === this.AppType.AppIOS || this.appType === this.AppType.AppAndroid) {
                    this.endChatClient(this.ChatFinishBy.Client);
                }
                ChatService.SaveReportEvents(this.keyChat, 600);
                let SignalR: any = this.$refs.SignalR;
                SignalR.ConnectionStop();
                setTimeout(() => { this.cuestionario() }, 2500)
            }
            return false;
        },
        endChatBot(guidFinishBy: any) {
            let data = { KeyChat: this.keyChat, GuidFinishBy: guidFinishBy };
            ChatService.FinishChatBot(data).then().catch();
            this.clearInactivity();
            this.inactivaAccountList();
            if (this.ShowQualtrixQuiz()) {
                this.validateQualtrixQuiz(null, "");
            }
        },
        endChatClient(guidFinishBy: any) {
            this.footerDisabled = true;

            if (this.isFirstClosechat) {
                this.isFirstClosechat = false;
                ChatService.SaveReportEvents(this.keyChat, 500);
            }
            if (!this.isBot) {
                let SignalR: any = this.$refs.SignalR;
                SignalR.endConnection(guidFinishBy, this.guidSystemCallCenter);
            } else {
                let DirectLine: any = this.$refs.DirectLine;
                DirectLine.directLineEnd();
                if (guidFinishBy === this.ChatFinishBy.Inactivity) {
                    this.writeMessage(this.inactivityMessage, this.typeUser.Server);
                    this.showTypingBot = false;
                }
                this.endChatBot(guidFinishBy);
            }
        },
        EndChatSignalR(finishBy: any) {
            if (finishBy.toLowerCase() === this.ChatFinishBy.Agent.toLowerCase()) {
                this.writeMessage({ message: this.messageEndChatAgent }, this.typeUser.Agentclose);
                if (!this.isBot) {
                    setTimeout(() => { this.CloseConversation() }, 1000)
                }
            } else if (finishBy.toLowerCase() === this.ChatFinishBy.Inactivity.toLowerCase()) {
                if (!this.isBot) {
                    setTimeout(() => { this.CloseConversation() }, 1000)
                }
            }
        },
        /** Finaliza cerrar conversacion */
        /**Signal R invoca transferencia */
        doTransfer(transferText: any) {

            if (this.originBot == "TelcelBot") {
                document.title = "Chat en Vivo Telcel";
            } else {
                document.title = "Chat en Vivo Claro";
            }

            this.getSentReceivedViewed(transferText);
            this.footerDisabled = true;
            let SignalR: any = this.$refs.SignalR;
            SignalR.cargaInicial(transferText);
            this.isBot = false;
            this.showVoice = this.showVoice ? String(localStorage.getItem('showVoiceAgent')) == "true" : this.showVoice;
            if (this.showVoice) {
                $(".mytext-default").css("width", "calc(100% - 80px)");
            } else { $(".mytext-default").css("width", "calc(100% - 40px)"); }
            this.clearInactivity()
            if (this.dataSaveComplementChat) {
                chatService.UpdateChatTdd(this.ChatComplete);
            }
        },
        isTypingCallback() {
            let scroll: any = document.querySelector(".scroll");
            setTimeout(() => { let containerChat: any = document.querySelector("#containerChat"); scroll.scrollTop = containerChat.scrollHeight; }, 50);
            if (!this.isBot) {
                this.mouseMove();
                let SignalR: any = this.$refs.SignalR;
                SignalR.isTypingCallback(this.userName);
            }
        },
        initClientChatAgent() {
            this.isBot = false;
            this.footerDisabled = false;
            this.startCountInactivity();
        },
        /** Inicia Confirmacion de lectura */
        getSentReceivedViewed(text: any) {
            let arrayText = text.split('|');
            let desc = arrayText[arrayText.length - 1];
            chatService.getSentReceivedViewed(desc).then(result => {
                if (result != null && result.data.guidSystemCampaMarketing != null) {
                    this.IsEnableSentReceivedViewed = result.data.isEnableSentReceivedViewedClient;
                }
            }).catch(error =>
                console.error("Ocurrio un error", error)
            );
        },
        ShowSentReceivedViewed(name: any) {
            this.listConversation.forEach((item: Chat) => {
                if (item.typeUser == this.typeUser.Client && item.sentReceivedViewed != '' && item.sentReceivedViewed != 'Viewed') {
                    item.sentReceivedViewed = name;
                }
            });
        },
        /** Finaliza Confirmacion de lectura */
        /** Inicia Metodos para Qualtrix */
        initInfoForQualtrix() {
            this.fechaInicioQualtrix = this.formatAMPM(new Date());
        },
        async updateInfoForQualtrix() {
            let data: any = {};

            let messagesCount = this.listConversation.length;

            data.motivoVisita = this.listConversation[messagesCount - 1].message +
                this.listConversation[messagesCount - 2].message +
                this.listConversation[messagesCount - 3].message;

            await ChatService.InfoQualtrix(this.keyChat).then(resp => {
                data.LineNumber = resp.data.numberClient;
                data.Email = resp.data.emailClient;
                data.ClientType = resp.data.descriptionClient;
                data.IsAuth = resp.data.isAuthenticate.toString();
            }).catch(error => {
                console.error("Ocurrio un error", error);
            });

            return data;
        },
        ShowQualtrixQuiz() {
            if (this.numberInteractions < 3 || this.quizOnOff !== 1 || !this.isBot) return false;
            //Para activar la encuesta la siguiente linea retornar true.
            return false;
        },
        ObtainButtonLabelFromMessage(str: string) {
            let firstTag = /<b>/gi;
            const first = str.search(firstTag);

            let secondTag = /<\/b>/gi;
            const second = str.search(secondTag);

            if (first === -1 || second === -1) return "Califícame aquí"

            const buttonMessage = str.substr(first + 3, second - first - 3);

            return buttonMessage;

        },
        async validateQualtrixQuiz(cardActions: any, message: string) {

            let cardAction = new CardActions();
            cardAction.title = this.ObtainButtonLabelFromMessage(message);
            cardAction.type = "imBack";
            cardAction.value = "Encuesta Mi Telcel";

            let qualtrixData = await this.updateInfoForQualtrix();

            let element = {
                typeMessage: "textPlain",
                data: {},
                ContentChatBot: "Quiz Qualtrix CCB",
                message: "",
                topicName: "EncuestaQualtrix",
                guidKeyChat: this.keyChat,
                channel: this.sChannel,
                region: this.Region,
                dateInit: this.fechaInicioQualtrix,
                motivoVisita: qualtrixData.motivoVisita,
                lineNumber: qualtrixData.LineNumber,
                email: qualtrixData.Email,
                clientType: qualtrixData.ClientType,
                isAuth: qualtrixData.IsAuth,
                qualtricsAuxBoolean: true,
                cardActions: cardActions
            }

            if (this.ShowQualtrixQuiz()) {
                element.data = { "cardAction": cardAction }
            }

            this.listConversation.forEach((element: any) => {
                if (element.topicName == "EncuestaQualtrix") {
                    element.qualtricsAuxBoolean = false;
                }
            });

            this.containQuiz = false;
            this.writeMessage(element, "Bot");
        },
        ValidateQualtrixQuizInAnswer(message: string) {
            const surveyLink = "<a target='_blank' href='" + this.surveyLink + "'><b> Califícame aquí </b></a>";

            if (message.includes(surveyLink)) {
                message = message.replace(surveyLink, "Califícame ");
            }
            else if (message.replace(/ /g, "").includes(this.surveyLink)) {
                this.containQuiz = true;
                message = message.replace(this.surveyLink, '');
            }

            return message;
        },
        observer() {
            const target = document.getElementsByClassName("QSIWebResponsive");
            if (!target[0]) {
                window.setTimeout(this.observer, 500);
                return;
            }
            this.observerByFrame();
        },
        observerByFrame() {
            const tagFrame = document.getElementsByClassName("QSIWebResponsive");
            var style = window.getComputedStyle(tagFrame[0]);

            if (style.display !== 'none') {
                window.setTimeout(this.observerByFrame, 500);
                return;
            }

            this.closeQualtrixInClose = true;
            this.CloseConversation();
        },
        autoClick() {
            const btnQuizQualtrix = document.getElementById("btnShowQuiz");

            if (btnQuizQualtrix === null) {
                window.setTimeout(this.autoClick, 500);
                return;
            }

            if (this.setClick) {
                window.setTimeout(this.autoClick, 3000);
                this.setClick = false;
            }

            btnQuizQualtrix.click();
        },
        /** Finaliza Metodos para Qualtrix */
        /**Utilerias */
        enviarDocumentosBot(text: any) {
            let DirectLine: any = this.$refs.DirectLine;
            DirectLine.sendActivityToBot(text, this.uuidv4(), "button");
        },
        mouseMove() {
            if (!this.isBot && this.ShowSentReceivedViewedAcesor) {
                let SignalR: any = this.$refs.SignalR;
                SignalR.isSentReceivedViewedCallback("Viewed");
            }
        },
        mouseUpPageComplete() {
            if (this.showVoice) {
                let record: any = this.$refs.record;
                record.RecordSpeechStop();
            }

        },
        clickEmoji(event: any) {
            this.text = event.textComplete;
            var textMessageId: any = document.getElementById('mytextArea');
            setTimeout(() => {
                textMessageId.selectionStart = event.startPosition + 2;
                textMessageId.selectionEnd = event.startPosition + 2;
            }, 100);
        },
        autoResize() {
            let aux: any = $(window);
            let total = this.centerHeight + this.heightFooter + 42
            aux.height(total);
            $(window).scrollTop(aux.height());
            let body = $('body');
            body.height(this.centerHeight);


            setInterval(() => {
                this.text = this.text.substr(0, 150);
                let heightTxtArea: any = document.getElementsByTagName('fieldset')[0];
                if (heightTxtArea != undefined) {
                    this.heightTxtArea = heightTxtArea.scrollHeight + 2;
                }
                let textAreaInput: any = document.getElementById('footer');
                let heightContaainerEmoji: any = document.getElementsByClassName('emoji-container-menu')[0];
                this.heightContainEmoji = (heightContaainerEmoji != undefined ? heightContaainerEmoji.offsetHeight : 0);
                if (textAreaInput != undefined && textAreaInput.scrollHeight <= 500) {
                    this.heightFooter = textAreaInput.scrollHeight;
                }
                this.heightFooter = this.heightFooter + this.heightContainEmoji;
            }, 1);
        },
        uuidv4() {
            return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
                let r = Math.random() * 16 | 0,
                    v = c == 'x' ? r : (r & 0x3 | 0x8);
                return v.toString(16);
            });
        },
        formatAMPM(date: Date) {
            let hours = date.getHours();
            let minutes = date.getMinutes();
            let ampm = hours >= 12 ? 'PM' : 'AM';
            hours = hours % 12;
            hours = hours ? hours : 12;
            let strTime = hours + ':' + (minutes < 10 ? '0' + minutes : minutes) + ' ' + ampm;
            return strTime;
        },
        typingIcon() {
            if (this.originBot == "TelcelBot") {
                this.iconAvatar = require('@/assets/Img/Telcel/icon-avatar.png');
                this.imgTypingGif = require('@/assets/Img/Telcel/bot_03.gif');
                this.iconAgent = require('@/assets/Img/Telcel/icn_agente.png');
            } else {
                this.iconAvatar = require('@/assets/Img/Claro/icon-avatar.png');
                this.imgTypingGif = require('@/assets/Img/Claro/bot_03.gif');
                this.iconAgent = require('@/assets/Img/Claro/icn_agente.png');
            }
        },
        limpiarStorage() {
            localStorage.removeItem("cuestionarioService-" + this.keyChatUrl);
        },
        /**Encuesta */
        cuestionario() {
            ChatService.getCuestionario(this.isBot, this.keyChat, this.appType, this.sChannel).then(resp => {
                this.typeView = this.typesView.questionnaire;
                this.questionnaireStatusResponse = this.questionnaireStatus.sinContestar;
                this.llenarValoresCuestionario(resp.data);
            }).catch(error => {
                console.error("Ocurrio un error al obtener el cuestionario \n", error)
            });
        },
        llenarValoresCuestionario(resp: any) {
            this.RedirectUrl = resp.RedirectUrl;
            this.closingAction = resp.ClosingAction;
            this.questionnaire = resp.listQuestion;
            this.waClientNumber = resp.WAClientNumber;

            localStorage.setItem('UrlBaseChat', resp.UrlBaseChat);

            this.AppType = resp.AppType;
            this.isBot = resp.isBot;
            this.sChannel = resp.NameChannel;
            this.waClientNumber = resp.WAClientNumber;
            setTimeout(() => { $(".vue-slider-dot").css("display", "none"); }, 100);
        },
        clickSlider(idx: any) {
            let elemento: any = $("#idSlider-" + idx + " > div > .vue-slider-dot");
            console.log("elemento: #idSlider-" + idx + " > div > .vue-slider-dot -------> ", elemento);
            elemento.css("display", "flex");
        },
        surveyEnd() {
            this.disabledBtnFinalizaEncuesta = true;
            var array: any = [];
            this.questionnaire.forEach((item: any) => {
                if (item.response != undefined && item.response != null) {
                    array.push({
                        guidChat: this.keyChat,
                        typeQuestionKpi: item.guidQuestionKpiType,
                        typeQuestion: item.descQuestionType,
                        question: item.question,
                        answer: item.response + '',
                        order: item.ordinal,
                        guidSurveyQuestion: item.guidSurveyQuestion
                    });
                }
            });
            ChatService.SaveReportEvents(this.keyChat, 601);
            ChatService.SaveSurvey(array).then(resp => {
                let closingAction = this.closingAction;
                if (closingAction.toLowerCase() === 'close') {
                    this.processResponseReturn();
                } else {
                    let chanel = this.sChannel;
                    if ((chanel.toLowerCase() === "whatsapp" || chanel.toLowerCase() === 'wapp2web') && navigator.userAgent.toLowerCase().indexOf('safari/') > -1) {
                        this.processResponseReturn();
                    }
                    else {
                        window.location.href = this.RedirectUrl;
                    }
                }
                this.limpiarStorage();
                setTimeout(() => { this.$router.push("/Chat/FinalChat"); }, 1000);
            }).catch(error => {
                this.limpiarStorage();
                self.close();
                setTimeout(() => { this.$router.push("/Chat/FinalChat"); }, 1000);
                console.error("ocurrio un error", error);
            });
        },
        processResponseReturn() {
            setTimeout(() => {
                self.close();
                let chanel = this.sChannel;
                if (chanel.toLowerCase() === "whatsapp" || chanel.toLowerCase() === 'wapp2web') {
                    let waClientNumber = this.waClientNumber
                    window.open("https://api.whatsapp.com/send?phone=" + waClientNumber);
                }
                setTimeout(() => { this.$router.push("/Chat/FinalChat"); }, 1000);
            }, 500);

        },
        metodoIframe(e: any) {
            let DirectLine: any = this.$refs.DirectLine;
            var messageId = this.uuidv4();
            let fromUseCase: any = $(".fromUseCase");
            let fromUseCaseField: any = "";
            this.restartCounter();
            if (fromUseCase != undefined && fromUseCase.length > 0) {
                let length: any = fromUseCase.length;
                fromUseCaseField = fromUseCase[length - 1].defaultValue;
            }
            if (e.origin === this.urlExternalFrames) {
                if (e.data !== "") {
                    var jsonResult = e.data;
                    var text = "#ExternalFrameResult|" + fromUseCaseField + "|" + jsonResult.success + "|" + jsonResult.code + "|" + jsonResult.errorDescription;
                    console.log(text);
                    this.writeMessage(jsonResult.ErrorDescription, this.typeUser.Bot);
                    DirectLine.sendActivityToBot(text, messageId, "");
                }
            } else if (e.origin === String(this.urlGenericExternalFrame) && !isNaN(e.data.success)) {
                var jsonResult = e.data;
                if (jsonResult.success && jsonResult.code === 200 && (jsonResult.ErrorDescription === "" || jsonResult.ErrorDescription === null)) {
                    jsonResult.ErrorDescription = "Operación exitosa"
                }
                var text = "#ExternalFrameResultGeneric|" + fromUseCaseField + "|" + jsonResult.success + "|" + jsonResult.code + "|" + jsonResult.ErrorDescription;
                console.log(text);
                let htmlIframe = "<span class ='color-text-bot container-messages-client margin-agent-chat'>" + jsonResult.ErrorDescription + "</span>";
                let response = { typeMessage: "textPlain", timestamp: new Date().getTime(), message: htmlIframe }
                this.writeMessage(response, this.typeUser.Bot);
                DirectLine.sendActivityToBot(text, messageId, "");
            }
        },
    },
});
