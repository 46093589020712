export default class QualtrixData{
    public Region: string;
    public Channel: string;
    public Service: string;
    public DateTimeInit: string;
    public DateTimeEnd: string;
    public LineNumber: string;
    public Email: string;
    public IdConversation: string;
    public ClientType: string;
    public ReasonVisit: string;
    public IsAuth: string;

    constructor() {
        this.Region = '';
        this.Channel = '';
        this.Service = '';
        this.DateTimeInit = '';
        this.DateTimeEnd = '';
        this.LineNumber = '';
        this.Email = '';
        this.IdConversation = '';
        this.ClientType = '';
        this.ReasonVisit = '';
        this.IsAuth = '';
    }
}