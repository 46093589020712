
/* eslint-disable */
import Vue from "vue";
import moment from 'moment'

export default Vue.component("BalanceListClaroBotPR", {

    props: [
        "item",
        "claseGeneral",
        "originBot",
        "pais"
    ],
    data() {
        return {
            dialogBalanceList: false,
            options: {
                plotOptions: {
                    radialBar: {
                        track: {
                            background: '#e0e0e0',
                        },
                        dataLabels: {
                            show: true,
                            name: {
                                show: false,
                            },
                            value: {
                                show: true,
                                fontSize: '18px',
                                fontFamily: undefined,
                                fontWeight: 400,
                                color: undefined,
                                offsetY: 0,
                                formatter: function (val: any) {
                                    return val.toFixed(0) + '%'
                                }
                            }
                        }
                    },
                    
                },
                colors: ["#DA291C"],
            },
        }
    },
    mounted() {
        this.options.plotOptions.radialBar.track.background = "#e0e0e0";
        this.options.colors = ["#DA291C"]
        
            
    },
    methods: {
        obtenFechaActual(fechaActual: Date) {
            return fechaActual ? moment(fechaActual).lang("es-mx").format("DD/MM/YYYY") : "Error";
        },
        validaTxt(txt: any) {
            return txt != undefined && txt != null && txt.length > 0;
        },
        validaObject(txt: any) {
            return txt != undefined && txt != null;
        },
        obtenImg(name: any) {
            var auxImg = '';
            try {
                auxImg = require('@/assets/Img/Claro/' + this.pais + '/' + name);
            } catch {
                console.error("no se encontro Imagen: " + name);
            }
            return auxImg != null ? auxImg : '';
        }

    }

})
