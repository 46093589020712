import { render, staticRenderFns } from "./AccountList.vue?vue&type=template&id=816da64e&scoped=true"
import script from "./AccountList.vue?vue&type=script&lang=ts"
export * from "./AccountList.vue?vue&type=script&lang=ts"
import style0 from "./AccountList.vue?vue&type=style&index=0&id=816da64e&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "816da64e",
  null
  
)

export default component.exports