
/* eslint-disable */
import Vue from "vue";
export default Vue.component("CarrouselPromo", {
    props: [
        "item",
        "claseGeneral"
    ],
    data() {
        return {
            dialogCarrousel: false,
            carousel: 0,
            cycle:true,
        };
    },
    mounted() {
        window.addEventListener("keydown", (event) => {
            const keyName = event.key;
            if(keyName == "ArrowRight"){
                this.carousel ++ ;
            }
            else if(keyName == "ArrowLeft"){
                this.carousel --;
            }
        })
    },
    methods:{
        goToWebSite(url:any){
            window.open(url, "_blank");
        }
    }
});
