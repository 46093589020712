
/* eslint-disable */
import azureService from "@/services/azure.service";
import axios from "axios";
import Vue from "vue";

export default Vue.component("SpellCheck",{
    props: [
        "urlApiSpellCheck",
        "pathSpellCheck",
        "paramsSpellCheck",
        "KeySpellCheck"
    ],
    data(){
        return{
            results:[],
            API_KEY_COOKIE: "spell-check-api",
        }
    },
    methods: {
        getSubscriptionKeyLocalStorage():string {
            var key = localStorage.getItem(this.API_KEY_COOKIE) || "";
            if (key.length !== 32) {
                localStorage.setItem(this.API_KEY_COOKIE, this.KeySpellCheck)
            }
            return localStorage.getItem(this.API_KEY_COOKIE) || "";
        },

        getCorrectText(text:string){
            const wrapper = async() => {
                var url = this.urlApiSpellCheck + this.pathSpellCheck + this.paramsSpellCheck +'&mode=spell&text=';
                var subscriptionKey:string = this.getSubscriptionKeyLocalStorage();
                var results;
                var newText = '';
                var textArray = text.split(' ');

                const spellRequest = async(textToCheck:string) => {
                    var response = await axios.post(url+textToCheck, null, {headers: {
                                                'Ocp-Apim-Subscription-Key': subscriptionKey,
                                                'Content-Type': 'application/x-www-form-urlencoded'
                                            }});
                    return response;
                };
                const correctText = function (text:string, bad:string, good:string, offset:number) {
                    return text.substring(0, offset) + good + text.substring(offset + bad.length, text.length);
                };

                if (text.includes('@'))
                {
                    var aux = '';
                    for (var i = 0; i < textArray.length; i++)
                    {
                        if (!textArray[i].includes('@')) {
                            results = await spellRequest(textArray[i]);
                            if (results != null && results != undefined && results.data.flaggedTokens != undefined && results.data.flaggedTokens != null && 
                                results.data.flaggedTokens.length > 0) {
                                results = results.data.flaggedTokens;
                            }
                            else{
                                results = null;
                            }

                            if (results != null) {
                                aux += results[0].suggestions[0].suggestion + ' ';
                            }
                            else {
                                aux += textArray[i] + ' ';
                            }
                        }
                        else {
                            aux += textArray[i] + ' ';
                        }
                    }
                    aux = aux.trim();
                    if (aux != text) {
                        newText = aux;
                    }
                }
                else
                {                    
                    results = await spellRequest(text);
                    if (results != null && results != undefined && results.data.flaggedTokens != undefined &&
                        results.data.flaggedTokens != null && results.data.flaggedTokens.length > 0) {
                        results = results.data.flaggedTokens;
                    }
                    else{
                        results = null;
                    }
                    var xoff = 0;
                    if (results != null) {
                        newText = text;
                        results.forEach((sugg:any) => {
                            newText = correctText(newText, sugg.token, sugg.suggestions[0].suggestion, sugg.offset + xoff);
                            xoff += sugg.suggestions[0].suggestion.length - sugg.token.length;
                        });
                    }
                    else {
                        newText = '';
                    }
                }                
                this.$emit("messageTextSpellCheck", newText);
            };
            wrapper().catch((err:any)=>{
                console.error("Ocurrió un error al invocar el servicio SpellCheck", err);
                this.$emit("messageTextSpellCheck", '');
            });      
        }
    }
})
