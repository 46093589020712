
import Vue from "vue";

export default Vue.component("AccountList", {
    props: [
        "item",
        "claseGeneral",
        "originBot"
    ],
    data(){
        return{
            select: {value:'',text:''},
            items: Array(),
        }    
    },
    mounted() {
        this.$emit("footerDisabled", this.item.data.footerDisabled);
        this.createList()
    },
    methods: {
        createList: function () {
            const accounts = this.item.data.associatedAccounts
            var list = []
            var tempObj = {}
            for(var i in accounts){
                tempObj = {
                    index: i,
                    value: accounts[i].accountNumber,
                    text: accounts[i].accountNumber+' '+accounts[i].accountTypeName
                }
                list.push(tempObj)
            }
            this.items = list
        }
    },
    watch: {
        select: function (val:any) {
            var object = this.item.data.associatedAccounts[val.index]
            this.$emit('selectAccount',object);
            this.$emit("footerDisabled", false);
        }
    }

})
