/* eslint-disable */
import axios from 'axios'
import {URL} from './URL_SERVICIOS'
const API_URL = URL.PINCIPAL

class TranslateService {
  TranslatMesssage (activityWrapper:any){
    var url = localStorage.getItem('UrlBaseChat') +"/TranslateActivity";//descomentar para PROD
    //var url = "https://localhost:44350/api/Storage/TranslateActivity";
    const headers = { 'Accept': 'application/json', 'Content-Type': 'application/json'}
    return axios.post(url,JSON.stringify(activityWrapper),{headers:headers});
  }
  TranslateAgentMessage(jsonWrapper:any){
    var url = localStorage.getItem('UrlBaseChat') +"/TranslateAgents"
    const headers = { 'Accept': 'application/json', "Content-Type": "application/json"}
    return axios.post(url,JSON.stringify(jsonWrapper),{headers:headers});
  }
  UrlUploadFiles(formData:any){
    var url = localStorage.getItem('UrlBaseChat') +"/UploadFilesToBlob"
    return axios({
      method: 'post',
      url: url,
      data:formData,
  })
  }
  UrlUploadFilesBot(formData:any,typeDocument:any){
    var url = localStorage.getItem('UrlBaseChat');
    if(typeDocument == 'altaSim'){
      url = url +"/UploadFilesToBlobAc";
    }else{
      url = url +"/UploadFilesToBlob";
    }
    
    return axios({
      method: 'post',
      url: url,
      data:formData,
    })
  }
}
export default new TranslateService()