
/* eslint-disable */
import Vue from "vue";
import jQuery from "jquery";
let $ = jQuery;
export default Vue.component("CarrouselImage", {
    props: [
        "item",
        "claseGeneral"
    ],
    data() {
        return {
            dialogCarrousel: false,
            carousel: 0,
            cycle:true,
        };
    },
    mounted() {
        window.addEventListener('keydown', (event) => {
            const keyName = event.key;
            if(keyName == 'ArrowRight'){
                this.carousel ++ ;
            }
            else if(keyName == 'ArrowLeft'){
                this.carousel --;
            }
        })
    },
    methods:{
        widthTamanioImgPopUp(url:any){
            const img = document.createElement("img");
            img.src = url;
            img.id = "ImgPrueba";
            document.body.appendChild(img);
            let fotoHeight:any = $("#ImgPrueba").innerHeight();
            let fotoWidth:any = $("#ImgPrueba").innerWidth();
            
            $("#ImgPrueba").remove();
            if(fotoHeight < fotoWidth){
                return 'width: 100%'
            }else{
                return 'width: 300px;'
            }
            
        },
    }
});
