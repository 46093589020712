
/* eslint-disable */
import Vue from "vue";
import jQuery from "jquery";
let $ = jQuery;

let timerId:any= null;
export default Vue.component("Typing",{
  props: {
    showTypingBot: Boolean,
    userName:String,
    originBot:String,
    claroOscuro:String,
    pais:String,
    iconAvatar:String,
    imgTypingGif:String,
    iconAgent:String,
    isBot:Boolean
  },
  data() {
    return {
        agentText:"",
        emisorMessage: { Me: 'me', You: 'you', Server: 'server' },
        BotName: { Bot: 'Bot', TelcelBot: 'TelcelBot', ClaroBot: 'ClaroBot'},
        centerHeight:document.documentElement.clientHeight,
        showTypingAgent: false,
    };
  },
  mounted() {
    scroll();
  },
   
  methods: {
    scroll(){
        setTimeout(function () { 
            const scroll:any = document.querySelector(".scroll");
            var containerChat:any = document.querySelector("#containerChat"); 
            scroll.scrollTop = containerChat.scrollHeight;
        }, 100);
    },
    typingAgent(name:any) {
      if (this.evaluateOwner(name) !== 'me') {
        this.showTypingAgent = true;
        this.agentText = name + " esta escribiendo...";
        $('.chat-typing').fadeIn(100, function () {});
        clearTimeout(timerId);
        timerId = setTimeout(() => { 
            $("#id-chat-typing").fadeOut(300, function () { });
        }, 1000);
      }
    },
    evaluateOwner(owner:any) {
      if (owner === this.userName) {
          return this.emisorMessage.Me;
      }
      else if (owner.toLowerCase() === this.emisorMessage.Server) {
          //userName = owner;
          return this.emisorMessage.Server;
      }
      else if (owner === this.BotName.Bot || owner === this.BotName.TelcelBot || owner === this.BotName.ClaroBot) {
          return this.BotName.Bot;
      }
      else {
          return this.emisorMessage.You;
      }
  }
  },
});
