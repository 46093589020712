
import Vue from 'vue'

export default Vue.component("BlueCircle",{
   props: [
        "item",
        "claseGeneral",
        "originBot",
    ],
    data(){
        return{
            PagarAntesDel:"Pagar antes del",
            dialogBalanceList:false,
            dialog:false,
            SlideType :{
                BodySlideBalance : 0,
                BodySlideBalanceSuspended : 1,
                BodySlideCirculoAzul : 2,
                BodySlideForPeriod : 3,
                BodySlideForTime : 4,
                BodySlideSocialNetworks : 5,
                BodySlideTable : 6,
                BodySlideWithGraph2Columns : 7,
                BodySlideSocialNetworksCluster : 8,
            },
            options: {
                plotOptions: {
                    radialBar: {
                        startAngle: -90,
                        endAngle: 90,
                        track: { background: '#e0e0e0', startAngle: -90, endAngle: 90, },
                        dataLabels: {
                            show: true, 
                            name: {
                                show: false, 
                            }, 
                            value: { 
                                show: true,
                                fontSize: '18px',
                                fontFamily: undefined,
                                fontWeight: 400,
                                color: undefined,
                                offsetY: 0,
                                formatter: function (val:any) {
                                    return val.toFixed(0) + '%'
                                }
                            }
                        }
                    }
                }
            },
        }
    },
    mounted() {
        if(this.originBot == "TelcelBot"){
            this.options.plotOptions.radialBar.track.background = "#e0e0e0";
        }else{
            this.options.plotOptions.radialBar.track.background = "#DA291C";
        }
    },

})
