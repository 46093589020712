
/* eslint-disable */
import Vue from "vue";
import BalanceList from '@/components/ContenidoChatBot/BalanceList.vue'
import BalanceListClaroBot from '@/components/ContenidoChatBot/BalanceListClaroBot.vue'
import BalanceListClaroBotEC from '@/components/ContenidoChatBot/BalanceListClaroBotEC.vue'
import BalanceListClaroBotPR from '@/components/ContenidoChatBot/BalanceListClaroBotPR.vue'
import BalanceListClaroBotCL from '@/components/ContenidoChatBot/BalanceListClaroBotCL.vue'
import CarrouselImage from '@/components/ContenidoChatBot/CarrouselImage.vue'
import CarrouselDevice from '@/components/ContenidoChatBot/CarrouselDevice.vue'
import Video from '@/components/ContenidoChatBot/Video.vue'
import FaqList from '@/components/ContenidoChatBot/FaqList.vue'
import Download from '@/components/ContenidoChatBot/Download.vue'
import BlueCircle from '@/components/ContenidoChatBot/BlueCircle.vue'
import CarrouselPromo from '@/components/ContenidoChatBot/CarrouselPromo.vue'
import EncuestaQualtrix from "@/components/ContenidoChatBot/EncuestaQualtrix.vue";
import AccountList from "@/components/ContenidoChatBot/AccountList.vue";
import AccountListBox from "@/components/ContenidoChatBot/AccountListBox.vue";
import UploadBot from "@/components/ContenidoChatBot/UploadBot.vue";
import ModalGeneric from '@/components/ContenidoChatBot/ModalGeneric.vue';
import Geo from "@/components/ContenidoChatBot/Geo.vue";

export default Vue.component("ContenidoChatBot", {
    props: [
        "item",
        "writeIcon",
        "claroOscuro",
        "pais",
        "originBot",
        "idx",
        "iconoAvatar",
        "typeUser",
        "previousMessage",
        "numberMessage",
        "nextMessage",
        "keyChat",
    ],
    components: {
        "BalanceList": BalanceList,
        "BalanceListClaroBot": BalanceListClaroBot,
        "BalanceListClaroBotEC": BalanceListClaroBotEC,
        "BalanceListClaroBotPR": BalanceListClaroBotPR,
        "BalanceListClaroBotCL": BalanceListClaroBotCL,
        "CarrouselImage": CarrouselImage,
        "Video": Video,
        "CarrouselDevice": CarrouselDevice,
        "FaqList": FaqList,
        "Download": Download,
        "BlueCircle": BlueCircle,
        "CarrouselPromo": CarrouselPromo,
        "EncuestaQualtrix": EncuestaQualtrix,
        "AccountList": AccountList,
        "AccountListBox": AccountListBox,
        "UploadBot": UploadBot,
        "ModalGeneric": ModalGeneric,
        "Geo": Geo,
    },
    data() {
        return {
            topicName: {
                ExternalFrame: "ExternalFrame",
                AccountList: "AccountList",
                AccountListBox: "AccountListBox",
                FaqList: "FaqList",
                BalanceList: "BalanceList",
                PlansList: "PlansList",
                Infographic: "Infographic",
                InfographicCarrousel: "InfographicCarrousel",
                DeviceList: "DeviceList",
                PromoList: "PromoList",
                Video: "Video",
                BillingDownload: "BillingDownload",
                BillingDownloadMX: "BillingDownloadMX",
                BlueCircle: "BlueCircle",
                EncuestaQualtrix: "EncuestaQualtrix",
                UploadBot: "upload",
                ModalGeneric: "ModalGeneric",
                Geo: "LocationPermissions"
            },
        };
    },
    computed: {
        claseGeneral() {
            return ' ' + this.claroOscuro + ' ' + this.originBot + ' ' + this.pais + ' ';
        }
    },
    methods: {
        dataGuidBtn(input: any, id: any) {
            input.id = id;
            return input.id;
        },

    },
});
