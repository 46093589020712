/* eslint-disable */
import axios from 'axios'
import {URL} from './URL_SERVICIOS'
const API_URL = URL.PINCIPAL

class AzureService {
    getToken (){
      return axios.get(API_URL+'/api/Home/getToken');
    }
    
    spellCheck(url:any,subscriptionKeyLocalStorage:any, text:any){
      var data = { 'text': text  };
      const formData = new URLSearchParams();
      formData.append('text',text);
      return axios({
        method: 'post',
        url: url,
        headers: { 
          'Ocp-Apim-Subscription-Key': subscriptionKeyLocalStorage,
          'Content-Type': 'application/x-www-form-urlencoded'
        },
        data : formData
      });
    }
}
export default new AzureService()