
/* eslint-disable */
import Vue from "vue";
import FileInput from "@/components/ContenidoChatAgente/FileInput.vue"
export default Vue.component("ContenidoChatAgent", {
    props: [
        "keyChat",
        "item",
        "writeIcon",
        "claroOscuro",
        "pais",
        "originBot",
        "idx",
        "iconAvatarAgente",
        "typeUser",
        "previousMessage",
        "numberMessage",
        "nextMessage",
    ],
    components:{
        "FileInput": FileInput,
    },
    data() {
        return {
        }
    },
})
