
/* eslint-disable */
import Vue from "vue";
import TranslateService from "@/services/translate.service";
import * as signalR from "@microsoft/signalr";
import { HubConnection } from "@microsoft/signalr/dist/esm/HubConnection";


let timerId:any= null;
let x=0;
export default Vue.component("SignalR",{
  props: {
    keyChat:String,
    userName:String,
    chatEnc:String,
    token:String,
    GuidChatState:String,
    guidSystemCallCenter:String,
    appType:String,

    SignalR_startRetries:Number,
    SignalR_serverTimeout:Number,
    SignalR_keepAliveInterval:Number,
    
    ChatFinishBy:Object,
    typeUser:Object,

    isBot:Boolean,
    isReconnect:Boolean,
    },
  data() {
    return {
        connection: Object(HubConnection),
    };
  },
  mounted() {},
  methods: {
    async cargaInicial(transferText:any) {
      try {
          let urlSignalR = localStorage.getItem('SocketServer');
          this.connection = new signalR.HubConnectionBuilder()
            .withUrl(urlSignalR+"/room")
            .withAutomaticReconnect([0, 2000, 10000, 20000, 40000])
            .configureLogging(signalR.LogLevel.Warning)
            .build();

        if (this.connection.state === 'Disconnected') {
            await this.connection.start();
        }

        this.connection.serverTimeoutInMilliseconds = this.SignalR_serverTimeout * 1000;
        this.connection.keepAliveIntervalInMilliseconds = this.SignalR_keepAliveInterval * 1000;


        this.connection.on("updateCallCenterNameClient", (newSystemCallCenter:any, name:any) => {
            var data = {
                guidSystemCallCenter:newSystemCallCenter,
                name:name
            };
            this.$emit("updateCallCenterNameClient",data)
        });
        this.connection.on("SendMessage", (name:any, message:any, user:any, keyChat:any, isFinalMessage:any, isClientFinishChat:any, messageTextSpellCheck:any) => {
            if (!isClientFinishChat && name != 'Sistema') {
                this.TranslateAgentMessage(name, message,user);
            }
        });
        this.connection.on("transferAgent", (name:any, message:any, user:any, keyChat:any) => {
            console.log("Transferencia Signal R");
            if (message == "Transferencia" && name == 'server') {
                console.log("Transferencia - transferAgent");
                this.$emit("Transferencia",true);
            }
        });

        this.connection.on("InitialMesage", (name:any, message:any, user:any, keyChat:any, isFinalMessage:any, isClientFinishChat:any) => {
            this.$emit("restartCounter");
            var data = {
                typeUser : this.typeUser.Server, message:message
            }
            this.$emit("writeMessage",data);
        });

        this.connection.on("showWhoIsTyping", (name:any, keyChat:any) => {
            if (!this.isBot) {
                this.$emit("typingAgent",name);
            }
        });
        this.connection.on("initClientChat", () => {
            this.$emit("initClientChat");
        });
        

        this.connection.on("messageReceived", (username: string, message: string) => {
            console.info(message);
        });
        this.connection.on("updateRequest", (pendingChats:any) => {
            $("#pendingList").empty();
            pendingChats.forEach((chat:any) => {
                $("#pendingList").append("<li>" + chat.Customer + "</li>");
            });
        });

        this.connection.on("ShowSentReceivedViewed", (name:any, keyChat:any) => {
             setTimeout(()=> this.$emit("ShowSentReceivedViewed", name) , 500);
        });
        

        this.connection.on("EndChat", (customer:any, keyChat:any, finishBy:any) => {
            console.log("Chat Finalizado por Agente");
            this.$emit("EndChatSignalR",finishBy);
        });

        

        this.connection.on("EndChatUnusualState", () => {
            /*disableConversation();

            alert(cleanStringHtml(messageLostConnectionMobile));
            appInsights.trackEvent({ name: `isClient: isFinishedChat, ${this.keyChat == undefined ? 'No Keychat yet' : this.keyChat}, isAuthenticate ${token == "" ? 'false' : 'true'}` });

            setTimeout(function () {
                popitupencuesta();
            }, 1000);*/
        })

        this.connection.onclose((error:any) => {
        /*
            if (!isStopForProcess) {
                disableConversation();
                diffInMs = (Math.abs(new Date() - dateStartSecondPlane)) / 1000;

                if (diffInMs == undefined || diffInMs < timeInactivity) {
                    alert(cleanStringHtml(messageLostConnection));
                }
            }

            appInsights.trackEvent({ name: `isClient: OnClose, ${this.keyChat == undefined ? 'No Keychat yet' : keyChat} Exception: ${error} isAuthenticate ${token == "" ? 'false' : 'true'} isReconnect ${isReconnect}` });
            isStopForProcess = false;

            setTimeout(function () {
                popitupencuesta();
            }, 3000);*/
        });


        this.connection.onreconnecting((error:any) => {
            /*UpdateChatIsReconnecting(true);
            isReconnect = true;*/
        });

        this.connection.onreconnected((connectionId:any) => {
            /*getMessages();
            connectWithRoom();
            isReconnect = false;
            UpdateChatIsReconnecting(false);
            connection.invoke("validatechatstatus", keyChat)
                .catch(err => console.error(err.toString()));*/
        });
        console.info("Transeferencia: "+transferText);
        this.connectWithRoom(transferText);

      } catch (err) {
        console.error(err);
        alert("No fue posible establecer la conexión con el servidor, por favor recargue la pagina.");
      };
    },
    sendGroup(userName:any,text:any,typeUser:any,guidSystemCallCenter:any,hideNextMessage:any,textSpellCheck:any){
        text = this.validQuestion(text);
        var keyChat = this.keyChat;
        this.connection.invoke("sendGroup", keyChat , userName, text, typeUser.Client, false, guidSystemCallCenter, false, false, hideNextMessage, false,null,"T")
                    .catch((err:any) => {
                        console.error(err.toString());
                        });

    },
    validQuestion(mensaje:string) {
        let nuevoMensaje = "";
        let patt = /\?{2,}/i;

        if (mensaje.match(patt) != null) {
            let mensajeMatch:any = mensaje.match(patt);
            nuevoMensaje = mensaje.replace(mensajeMatch, "?");
        }
        else {
            nuevoMensaje = mensaje;

        }

        return nuevoMensaje;
    },
    async sleep(n:any) {
        return new Promise(resolve => setTimeout(resolve, n));
    },
    async start() {
        for (let i = 0; i < this.SignalR_startRetries; i++) {
            try {
                await this.sleep(1000);
                console.info(`Connection attempt ${i + 1}`);
                return await this.connection.start();
            }
            catch (err) {
                console.assert(this.connection.state === signalR.HubConnectionState.Disconnected);
                console.info(err);
            }
        }

        throw new Error("Could not connect to signalr");
    },
    async connectWithRoom(transferText:any) {
        try {
            if (this.connection.state === 'Disconnected') {
                await this.start();
            }
            if ((this.GuidChatState.toUpperCase() === "2A32BFBC-F911-4E4E-9185-829D0543F086") || (this.GuidChatState.toUpperCase() === "AAD82AAD-CA26-48F6-9D94-567EE6651F2A") || (this.GuidChatState.toUpperCase() === 'F5A39092-D8A5-48C9-AA2A-50D25B7662C7')) {
                var chatEnc = this.chatEnc;
                var token = this.token;
                var isReconnect = this.isReconnect;
                this.connection.invoke("Connect", chatEnc, token, transferText, '', isReconnect).catch((err:any) => {
                    console.error(err.toString())
                });
            }

            if (this.appType === "App") {
                this.connection.invoke("getMessagesChat", this.keyChat).catch((err:any) => console.error(err.toString()));
            }
            else {
                window.addEventListener("beforeunload", e => {
                    this.$emit("endChatClient",this.ChatFinishBy.Client)
                    var tmp = 0;
                    while (tmp < 10000000) { tmp++; }
                }, false);
            }
        } catch (err) {
            console.error(err);
            alert("No fue posible establecer la conexión con el servidor, por favor recargue la pagina.");
        };
    },
    TranslateAgentMessage(name:any, message:any, typeUser:any) {
        if (message.indexOf("hideMessageForClient::") < 0) {
            var jsonWrapper = {
                agentMessage: message
            };
            TranslateService.TranslateAgentMessage(jsonWrapper).then(resp => {
                var data = {  typeUser : typeUser[0].toUpperCase() + typeUser.slice(1), message:{message:resp.data,nameAgent:name} }
                this.$emit("writeMessage",data);
            }).catch(error => {
                console.error(error);
            });
        } 
    },
    endConnection(guidFinishBy:any, guidSystemCallCenter:any){
        this.connection.invoke("finishChat", this.keyChat, guidFinishBy, guidSystemCallCenter).catch((err:any) => console.error(err.toString()));
    },
    ConnectionStop(){
        this.connection.stop();
    },
    isTypingCallback(userName:any) {
        if(x == 0) {
            x=1;
            this.connection.invoke("whoIsTyping", this.keyChat, userName).catch((err:any) =>{
                console.error("this.keyChat: "+this.keyChat +" userName: "+ userName,err)
            }); 
        }
        clearTimeout(timerId);
        timerId = setTimeout(() => { 
            x = 0;
        }, 600);
    },
    
    isSentReceivedViewedCallback(status:any) {
        status = status + '|client';
        this.connection.invoke("SentReceivedViewed", this.keyChat, status).catch((err:any) =>{
            console.error(err.toString())
        });
    }
  },
});
