
/* eslint-disable */
import Vue from 'vue'
import TranslateService from "@/services/translate.service"
export default Vue.component("FileInput", {
    props: [
        "item",
        "claroOscuro",
        "pais",
        "originBot",
        "keyChat"
    ],
    data: () => ({
        chosenFile: '',
      rulesFileInput: [
        (value:any) => !value || value.size < 2000000 || 'El archivo pesa mas de 2 MB!',
      ],
    }),
    mounted(){
    },
    methods: {
        importTxt() {
            var archivoBase64 : string | ArrayBuffer | null = "";
            if (!this.chosenFile) {
                "No se ha cargado Archivo"
            } else {
                var formData = new FormData();

                formData.append("files", this.chosenFile);
                formData.append("keyChat", this.keyChat);

                TranslateService.UrlUploadFiles(formData).then(resp => {
                    this.$emit("SaveMesssage","Envío de documento");
                    let textResult = "hideMessageForClient::documentUrl:" + resp.data[0]
                    let SignalR: any = this.$refs.SignalR;
                    this.$emit("sendText",textResult);
                }).catch(error => {
                    console.error("ocurrio un error al subir el documento \n ####",error)
                });
                /*reader.readAsDataURL(this.chosenFile);
                reader.onloadend = () => {
                    
                    TranslateService.
                    var documentBase64 = reader.result;
                }*/
                
            }
        }
    }
})
