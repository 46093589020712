
/* eslint-disable */
import Vue from "vue";
import TranslateService from "@/services/translate.service";
import {
    DirectLine,
    ConnectionStatus
} from 'botframework-directlinejs'
import { ConsoleLoggingListener } from "microsoft-cognitiveservices-speech-sdk/distrib/lib/src/common.browser/ConsoleLoggingListener";

const TransferChatToAgent = "TransferChatToAgent";
const EndConversation = "EndConversationCmd";

export default Vue.component("DirectLine",{
    data: () => ({
        contador: 0,
        keyChat: {},
        idLastActivity: 0,
        ChannelWeb: 'ChannelWeb',
        isFirstMessage: true,
        conversationID: '',
        urlGenericExternalFrame: '',
        directLine:Object(DirectLine),
    }),
    computed: {

    },
    mounted() {},
    methods: {
        cargaInicial(conversationToken: any, keyChat: any, text : any) {
            var splitActivity = 0;
            this.directLine = new DirectLine({
                token: conversationToken,
                pollingInterval: 500
            });
            this.keyChat = keyChat;

            var messageId = this.uuidv4();
            let directline = this.directLine;
            console.log(directline);
            console.log(text);
            this.directLine.connectionStatus$.subscribe((connectionStatus: any) => {
                switch (connectionStatus) {
                    case ConnectionStatus.Uninitialized:
                        console.log("-----> Estatus DirectLine ConnectionStatus.Uninitialized " +connectionStatus);
                        break; // the status when the DirectLine object is first created/constructed
                    case ConnectionStatus.Connecting:
                        console.log("-----> Estatus DirectLine ConnectionStatus.Connecting " +connectionStatus);
                        break; // currently trying to connect to the conversation
                    case ConnectionStatus.Online:
                        console.log("-----> Estatus DirectLine ConnectionStatus.Online " +connectionStatus);
                        this.sendActivityToBot(text, messageId, "")
                        break; // successfully connected to the converstaion. Connection is healthy so far as we know.
                    case ConnectionStatus.ExpiredToken:
                        console.log("-----> Estatus DirectLine ConnectionStatus.ExpiredToken " +connectionStatus);
                        break; // last operation errored out with an expired token. Your app should supply a new one.
                    case ConnectionStatus.FailedToConnect:
                        console.log("-----> Estatus DirectLine ConnectionStatus.FailedToConnect " +connectionStatus);
                        break; // the initial attempt to connect to the conversation failed. No recovery possible.
                    case ConnectionStatus.Ended:
                        console.log("-----> Estatus DirectLine ConnectionStatus.Ended " +connectionStatus);
                        break; // the bot ended the conversation
                    default:
                        break;
                }
            });
            this.directLine.activity$
            .filter((activity:any) => activity.from.id.indexOf(this.ChannelWeb) < 0 && activity.text != undefined)
            .subscribe(
                (activity:any)  => {
                    splitActivity = parseInt((activity.id.split('|')[1]));
                    this.conversationID = activity.id.split('-')[0];
                    this.$emit("conversationID",this.conversationID)
                    if ((splitActivity > this.idLastActivity) || this.idLastActivity === undefined) {
                        if (this.isFirstMessage) {
                            this.isFirstMessage = false;
                            this.TranslatMesssage(activity);
                        } else {

                            if (activity.text.indexOf(TransferChatToAgent) >= 0) {
                                this.doTransfer(activity.text);
                            }
                            else if (activity.text == EndConversation) {
                                this.$emit('endChatClient');
                            }
                            else {
                                setTimeout(() => this.TranslatMesssage(activity), 100);
                                
                            }
                        }
                        this.idLastActivity = splitActivity;
                    }
                }
            );
        },

        sendActivityToBotHijo: function (text: any, uuidv4:any, inputType: any) {
            setTimeout(() => this.sendActivityToBot(text, uuidv4, inputType), 100);
        },

        sendActivityToBot: function (text: any, messageId: any, inputType: any) {
            var paramsChat = '{ "inputKind": "' + inputType + '" }';
            this.directLine.postActivity({
                from: { id: this.ChannelWeb, name: paramsChat },
                name: this.keyChat + "|" + messageId,
                type: 'message',
                text: text,
            }).subscribe(
                (id: any) => {
                    console.log("DirectLine PosActivity: "+ id, this.directLine.postActivity.name);
                },
                (error: any) => {
                    console.error("Error posting activity", error)
                }
            );
            console.debug("DirectLine PosActivity Name:", this.directLine.postActivity.name);
        },

        uuidv4: function () {
            return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
                var r = Math.random() * 16 | 0,
                    v = c == 'x' ? r : (r & 0x3 | 0x8);
                return v.toString(16);
            });
        },
        TranslatMesssage(activity: any) {
            this.contador = this.contador+1;
            var activityWrapper = {
                activity: activity,
                keyChat: this.keyChat,
                timestamp: new Date().getTime()
            };
            this.$emit('isflagExternalFrame',activityWrapper.activity.topicName == "ExternalFrame");
            if (activityWrapper.activity.topicName === "ExternalFrame") {
                this.urlGenericExternalFrame = JSON.parse(activityWrapper.activity.value).Url.replace("https://", "");
                this.urlGenericExternalFrame = "https://" + this.urlGenericExternalFrame.substr(0, this.urlGenericExternalFrame.indexOf("/"))
                this.$emit('urlGenericExternalFrame',this.urlGenericExternalFrame);   
            }
            TranslateService.TranslatMesssage(activityWrapper).then(resp => {
                this.contador --;
                this.$emit('txtBotResponse', resp.data);
                if(this.contador==0){
                    this.$emit("showTypingBot");
                }
            }).catch(error =>{
                this.contador --;
                if(this.contador==0){
                    this.$emit("showTypingBot");
                }
                console.error("Ocurrio un error al traducir el siguiente texto \n" + activity.text, error)
            })

        }, 
        doTransfer(transferText:any) {
            this.directLineEnd();
            this.$emit("doTransfer", transferText);
        },
        directLineEnd(){
            var paramsChat = '{ "inputKind": " " }';
            this.directLine.postActivity({
                from: { id: this.ChannelWeb, name: paramsChat },
                name: this.keyChat + "|" + this.uuidv4(),
                type: 'endOfConversation',
            }).subscribe(
                (id: any) => {
                    console.log("DirectLine endOfConversation:", this.directLine.postActivity.name);
                },
                (error: any) => {
                    console.error("Error DirectLine endOfConversation", error);
                    setTimeout(()=>this.directLine.end(),2000);
                }
            );
            
        }
    }
});
