
import Vue from "vue";
// import moment from 'moment';

export default Vue.component("AccountListBox", {
    props: [
        "item",
        "claseGeneral",
        "originBot"
    ],
    data() {
        return {}
    },
    mounted() {

    },
    methods: {

    },

})
