
import Vue from "vue";
export default Vue.component("Video", {
    props: ["item","claseGeneral"],
    data() {
        return{
            dialogVideo:false,
            dialogOpen:false
        };
    },
    mounted(){
        window.addEventListener('keydown', (event) => {
            const keyName = event.key;
            console.log(keyName);
            if(keyName == 'Escape' && this.dialogOpen){
                this.cerrar();
            }
        })
    },
    methods: {
        
        cerrar(){
            this.dialogVideo = false;
            this.dialogOpen = false;
            var videoIdAux:any = this.$refs.video_play;
            videoIdAux.pause();
        },
        showVideo() {
            this.dialogVideo=true;
            this.dialogOpen=true;
            var videoIdMinAux:any = this.$refs.min_video_play;
            var videoIdAux:any = this.$refs.video_play;
            videoIdMinAux.pause();
            setTimeout(()=>{videoIdAux.load();videoIdAux.play()},10);
        },
    },
});
