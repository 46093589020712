/* eslint-disable */
export default class CardActions {
    public type: string;
    public title: string;
    public image: string;
    public value: string;
  
    constructor() {
        this.type = '';
        this.title = '';
        this.image = '';
        this.value = '';
    }
  }