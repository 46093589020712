
import Vue from 'vue'

export default Vue.component("Download",{
   props: [
        "item",
        "topicName",
        "claseGeneral"
    ],
    methods:{
        goToWebSite(url:any){
            this.$emit("restartCounter");
            window.open(url, "_blank");
        },
        openFileBase64(base64URL:any, name:any){
            var sampleBytes = this.base64ToArrayBuffer(base64URL);
            this.saveByteArray([sampleBytes], name);
            this.$emit("restartCounter");
        },
        base64ToArrayBuffer(base64:any) {
            var binaryString = window.atob(base64);
            var binaryLen = binaryString.length;
            var bytes = new Uint8Array(binaryLen);
            for (var i = 0; i < binaryLen; i++) {
                var ascii = binaryString.charCodeAt(i);
                bytes[i] = ascii;
            }
            return bytes;
        },
        saveByteArray (data:any, name:any) {
            var blob = new Blob(data, { type: "application/pdf" }),
            url = window.URL.createObjectURL(blob);
            var win = window.open(url);
        }
    }
    
})
