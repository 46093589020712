
/* eslint-disable */
import Vue from "vue";
import moment from 'moment'

export default Vue.component("ModalGeneric", {
    
    props: [
        "item",
        "claseGeneral",
        "originBot",
    ],
    data() {
        return {
            dialogModal:false
        }
    }
})
