
/* eslint-disable */
import Vue from "vue";
import ChatService from "@/services/chat.service";
import jQuery from "jquery";
let $ = jQuery;


export default Vue.component("Emoji",{
  props: {
    claroOscuro: String,
    originBot: String,
    pais:String,
    footerDisabled: Boolean,
    footerDisabledRec: Boolean,
    isPassword: Boolean,
    text:String
  },
  data() {
    return {
      menu: false,
      imageEmoji: require("@/assets/Img/emoji.png"),
      listemojis: [],
      llenaEmojis: true,
    };
  },
  mounted() {
  },
  methods: {
    getEmojis(keyChatEmoji:any){
      ChatService.loadEmojis(''+ keyChatEmoji)
                .then((resp) => {
                    this.listemojis = resp.data.listData;
                    this.llenaEmojis = false;
                })
                .catch((error) => {
                    console.error("ocurrio un error al cargar los emojis", error);
                });
    },
    loadEmojis() {
      this.realizaScroll();
      if(this.menu){
        this.menu = false;
      }else{
        this.menu = true;
      }
    },
    addEmoji(emoji:string) {
      this.realizaScroll();
       var textMessageId:any = $('#mytextArea')[0];
      if(textMessageId.value.length < 149){
        var startPosition = textMessageId.selectionStart;
        var endPosition = textMessageId.selectionEnd;
        var textComplete = this.text.substr(0, startPosition) + emoji + this.text.substr(endPosition);
        this.$emit('ClickEmoji', {textComplete, startPosition}); 
      }
    },
    realizaScroll(){
      let scroll: any = document.querySelector(".scroll");
      setTimeout(() => { let containerChat: any = document.querySelector("#containerChat"); scroll.scrollTop = containerChat.scrollHeight; }, 50);
    }
  },
});
