
import Vue from "vue";

export default Vue.component("CarrouselDevice", {
    props: [
        "item","claseGeneral"
    ],
    data(){
        return {
            carousel:0,
            cycle:true,
        }
    },
    methods:{
        goToWebSite(url:any){
            window.open(url, "_blank");
        },
        validaListObject(obj: any) {
            return obj != undefined && obj != null && obj.length>0;
        },
    }
})
