/* eslint-disable */
import ExternalFrame from '@/models/ExternalFrame';
import CardActions from '@/models/CardActions'

export default class Chat {
  

  public message: string;
  public typeMessage: string;
  public typeUser: string;
  public guidKeyChat: string;
  public topicName: string;
  public nameAgent:string;
  public sentReceivedViewed:string;
  public timestamp: any;
  public time: any;
  public data: any;

  public cardActions: Array<CardActions>;
  public externalFrame:ExternalFrame;

  public disabled: any;
  


  constructor() {
    this.message = '';
    this.typeMessage = '';
    this.timestamp = '';
    this.cardActions = new Array<CardActions>();
    this.typeUser = '';
    this.time = '';
    this.guidKeyChat = '';
    this.externalFrame = new ExternalFrame();
    this.topicName = '';
    this.nameAgent= '';
    this.sentReceivedViewed = '';
    this.data='';
    this.disabled = false;
  }
}


